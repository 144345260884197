import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { EventBus } from '@/support/eventBus';
import { IWizardManager, IVideo } from '@/interfaces';

@Component
export default class RenameAssetComponent extends Vue {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    model = Globals.NewVideo;
    original = Globals.NewVideo;

    errors: string[] = [];
    busy = false;
    newFilterName: string = null;

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    video: IVideo;

    get saveDisabled() {
        return (!this.model.title || !this.model.study) || (
            this.model.title == this.original.title &&
            this.model.study == this.original.study );
    }

    created() {
        Debug.setDebugModule('RenameAsset', this);
    }

    mounted() {
        this.model = Object.assign({}, this.video);
        this.original = Object.assign({}, this.video);
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    async done() {
        if (!this.validateInput())
            return false;

        if (this.newFilterName)
            this.model.title = 'Append-' + this.newFilterName;

        Debug.log('renameAsset started', this.model.studio, this.model.study, this.model.title);
        this.busy = true;

        try {
            let response = await PlayerApi.renameAsset(this.model);

            this.model.title = response.title;
            this.video.title = response.title;
            this.video.filter = response.filter;
            this.video.filterName = response.filterName;

            Debug.log("renameAsset SUCCESS", this.model.studio, this.model.id, this.model, response);

            EventBus.Instance.$emit(EventBus.VideosChanged, null);

            Util.showToast('Renamed ' + this.model.title);

            this.wizard.back();

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("Rename video FAILED", this.model.studio, this.model.id, this.model, message);
            Util.showToast('Failed to rename ' + this.model.title, true);
            return null;
        } finally {
            this.busy = false;
        }
    }

    cancel() {
        this.wizard.back();
    }

    validateInput() {
        this.newFilterName = null;
        this.errors.splice(0, this.errors.length);

        if (!this.model.title)
            this.errors.push('Name required');
        else if (this.video.filter)
            return this.validateFilterInput();
        else if (this.model.title != Util.sanitizeName(this.model.title))
            this.errors.push('Invalid name. Only alphabetical, numeric and dot dash underscores allowed.');
        // BUG: Azure Media Player cannot play filters with special characters


        if (!this.model.study)
            this.errors.push('Name required');
        else if (this.model.study != Util.sanitizeName(this.model.study))
            this.errors.push('Invalid study. Only alphabetical, numeric and dot dash underscores allowed.');

        return this.errors.length == 0;
    }

    /**
     * Split out filter name into Prefix @ Suffix
     */
    validateFilterInput() {
        const errorString = 'Invalid name. Only alphabetical, numeric, and certain special characters are allowed. Allowed special characters: ~ ^ ` * - _ = . $ < > @ { } (';
        let idx = this.original.title.indexOf(' @ ');
        if (idx != -1) {
            let prefix = this.original.title.substr(0, idx);
            if (prefix) {
                if (this.model.title.startsWith(prefix)) {
                    let suffix = this.model.title.substr(prefix.length + 3);
                    if (suffix) {
                        if (suffix != Util.sanitizeFilterName(suffix)) {
                            this.errors.push(errorString);
                            return false;
                        } else {
                            this.newFilterName = suffix;
                            return true;
                        }
                    }
                }
            }
        }

        if (this.model.title != Util.sanitizeFilterName(this.model.title)) {
            this.errors.push(errorString);
            return false;
        }

        return this.errors.length == 0;
    }
}
